import jwt_decode from 'jwt-decode';
import constants from './Constant';

const AuthService = {
  "redirecturi": '',

  "domain": "ltimindtree.com",
  "orgCode": "ORG000004",
  "scope": " https://graph.microsoft.com/User.Read",
  "publicKeyUrl": "https://login.microsoftonline.com/ff355289-721e-4dd7-a663-afec62ab9d54/discovery/v2.0/keys",
  "publicKeyXmlUrl": "https://login.microsoftonline.com/ff355289-721e-4dd7-a663-afec62ab9d54/federationmetadata/2007-06/federationmetadata.xml",
  "certificatePath": "/EntityDescriptor/Signature/KeyInfo/X509Data/X509Certificate/text()",
  "resource": "https://graph.microsoft.com",
  "loginUri": "https://login.microsoftonline.com",
  "tanentId": "ff355289-721e-4dd7-a663-afec62ab9d54",
  "clientId": "0caf37af-8e21-454f-912a-41657c3d7f93",
  "clientSecret": "axr8Q~H4pHl6OOZ4XFRAhQCWztjvn7cRg8~yncDM",
  "ssoEnabled": true,
  "redirectUri": "https://ultimamobileprod-ultimamobile-gateway.azuremicroservices.io/api/ultima-mobile/v1/oauthLoginMobile",
  // "redirectUri": "https://mtultimamobileqa.z30.web.core.windows.net/api/auth/v1/oauthLogin",
  // "capeUiRedirectionUrl": "aHR0cHM6Ly91bHRpbWF3b3Jrc3FhLmx0aW1pbmR0cmVlLmNvbS9ob21l",
  "capeUiRedirectionUrl":"aHR0cHM6Ly9tdHVsdGltYW1vYmlsZXFhLnozMC53ZWIuY29yZS53aW5kb3dzLm5ldC9jYXJk",
  "Uuid" : "ffa58ebb-95eb-499c-a650-62d31ea981c4",

    decodeToken(token){
        let decodedJwtToken;
        if (token && token != null) {
          decodedJwtToken = jwt_decode(token);
        }
        return decodedJwtToken;
    },
    readUserInfoFromToken(decodedToken){
        let userInfo;
        if (decodedToken && decodedToken != null) {
          userInfo = {};
          userInfo.orgCode = decodedToken.org_code;
          userInfo.orgName = decodedToken.org_Name;
          userInfo.username = decodedToken.upn;
          userInfo.email = decodedToken.email;
          userInfo.name = decodedToken.name;
          userInfo.acls = decodedToken.scp;
          userInfo.projectList = decodedToken.project_list;
          userInfo.source = decodedToken.source;
          userInfo.theme = decodedToken.theme;
          userInfo.mid = decodedToken.mid;
          userInfo.upn = decodedToken.preferred_username;
        }
        return userInfo;
    },
    checkTokenExpiry(token) {
      let isValid;
      if (token && token != null) {
        // console.log("decoaded token", this.decodeToken(token))
        let decodedToken = this.decodeToken(token),
          tokenExpiryEpoch = decodedToken.exp,
          currentSystemEpoch = Math.floor(new Date().getTime() / 1000),
          timeGapInSec = 60,
          // driftTime = this.getItemFromStorage('driftTime');
          driftTime = 0;
        if (
          tokenExpiryEpoch - currentSystemEpoch + (driftTime ? driftTime : 0) <=
          timeGapInSec
        ) {
          isValid = false;
        } else {
          isValid = true;
        }
      } else {
        isValid = false;
      }
      return isValid;
    },

    ssoRedirection(){
      const userDomainName = 'ltimindtreeMobile.com';
      // const userDomainName = 'ltimindtree.com';
      const userName = 'user';
      // const redirecturi = `https://mtultimamobileqa.z30.web.core.windows.net/card`;
      const redirecturi = this.getRedirctUri();
      const base64Clienturi = btoa(
        encodeURIComponent(redirecturi)
      );
      const ssoRedirectUri = this.generateSSOuri(userDomainName, userName, base64Clienturi);
      this.redirctLogin(ssoRedirectUri);
    },

    getRedirctUri() {
      return this.redirecturi && this.redirecturi !== this.getLoginUri()
        ? this.redirecturi
        : this.getHomeUri();
    },

    getLoginUri() {
      var hashIndex = window.location.href.indexOf("#");
      var loginUrl = window.location.href.substring(0, hashIndex) + "/login";
      return loginUrl;
    },

    getHomeUri () {
      var homeURL = constants.envUrl.replace("/api/", "/home");
      return homeURL;
    },

    generateSSOuri(domainName, username, redirecturi) {
      // let userParam = "&username=" + username;
      // return (`${this.loginUri}/${this.tanentId}/oauth2/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.Uuid}&nux=1&resource=${this.resource}&client-request-id=${this.Uuid}&nonce=${this.Uuid}&response_mode=query${userParam}`);
      return (
        constants.envUrl+
        "ultima-mobile/v1/ssoMobile/" +
        domainName +
        "/" +
        redirecturi +
        "/" +
        username
      );
    },
    redirctLogin(ssouri) {
      setTimeout(() => {
        if(!localStorage.getItem('loggedInTime')){
          localStorage.setItem("loggedInTime", new Date().valueOf().toString());
        }
        // var loginUrl = this.getLoginUri();
        window.sessionStorage.setItem("logged", "true");
        window.location.replace(ssouri);
        // window.location.replace(ssouri ? ssouri : loginUrl);
        localStorage.setItem('logged', 'true');
        console.log("redirect login***",ssouri)
        if (!ssouri) {
          console.log("Inside");
          window.location.reload();
        }
        // this.redirecturi = null;
      }, 10);
    },

    getRefreshTokenAPI(userName,refresh_token) {
      let decodedRefreshToken = encodeURIComponent(refresh_token),
      grant_type = 'refresh_token';
      // params = 'grantType=' + grant_type + '&refreshToken=' + decodedRefreshToken + '&username=' + userName;
      //  const headers = {
      //     // 'Content-type': 'application/x-www-form-urlencoded'
      //     'Content-Type': 'application/json'
      //   }
      // const url = `${constants.envUrl}ultima-mobile/v1/refreshTokenForMobile?${params}`;
      const url = `${constants.envUrl}ultima-mobile/v1/refreshTokenForMobile`;
      return url;
    },
    callLogoutUri() {
      let decodedTeamsToken = AuthService.decodeToken(sessionStorage.getItem('mobileAppAccessToken'));
      let userInfo = AuthService.readUserInfoFromToken(decodedTeamsToken);
      // loggedInUserInfo.setLoggedInUserInfo(userInfo);
      if (userInfo && userInfo.username) {
        let url = `${constants.envUrl}ultima-mobile/v1/mobileLogout/${userInfo.username}`;
        // let url = `${constants.envUrl}auth/v1/logout/${userInfo.username}`;
        // this.storage.clear();
        sessionStorage.clear();
        // $rootScope.$broadcast("oauth:loggedOut", url);
        return true;
      } else {
        return false;
      }
    },

    // logout(userName) {
    //   let logoutUri = `${constants.envUrl}ultima-mobile/v1/mobileLogout/${userName}`;
    //   // let logoutUri = `${constants.envUrl}auth/v1/logout/${userName}`;
    //   console.log("this.isValidUrl(logoutUri)",this.isValidUrl(logoutUri));
    //   if(this.isValidUrl(logoutUri)) {
    //     window.location.href = logoutUri;
    //   }
    // },

    isValidUrl(text,userName) {
     if((text.startsWith("https") || text.startsWith("http")) && (userName !== null)) return true;
     else return false;
    }

}
export default AuthService;